import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import emailjs from "emailjs-com";
import React from "react";
import "./Contact.css";

function Contact() {
  const { language } = useSelector((state) => state.settings);

  const formRef = useRef();
  const [done, setDone] = useState(false);
  const [userName, setUserName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isFormValid, setFormValid] = useState(true);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/; // Provera formata emaila
    return re.test(email);
  };

  const onSubmitForm = (event) => {
    if (!userName || !subject || !email || !message || !validateEmail(email)) {
      setFormValid(false);
      return;
    }
    setFormValid(true);
    setDone(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validiraj formu pre slanja
    onSubmitForm(event);

    if (!isFormValid) {
      return;
    }

    emailjs
      .sendForm(
        "service_vh6njl3",
        "template_x1uk9w9",
        formRef.current,
        "Q0Z2lHJyAR0A6XkCY"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          setTimeout(() => {
            setDone(false);
          }, 2000);
        },
        (error) => {
          console.log(error.text);
        }
      );

    // Očisti formu nakon slanja
    setUserName("");
    setSubject("");
    setEmail("");
    setMessage("");
  };

  return (
    <>
      <div className="contact" id="contact">
        <div className="contact-container">
          <div className="contact-container-wrapper">
            <div className="contact-container-wrapper-right">
              <h2 className="contact-container-wrapper-right-desc">
                {language === "En"
                  ? "If you have additional questions, write to us"
                  : "Ako imate dodatnih pitanja pišite nam"}
              </h2>
              <form ref={formRef} onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder={language === "En" ? "Your name" : "Vaše ime"}
                  name="user_name"
                  onChange={(event) => setUserName(event.target.value)}
                  value={userName}
                />
                <input
                  type="text"
                  placeholder={language === "En" ? "Title" : "Naslov"}
                  name="user_subject"
                  onChange={(event) => setSubject(event.target.value)}
                  value={subject}
                />
                <input
                  type="text"
                  placeholder="Email"
                  name="user_email"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                />
                <textarea
                  rows="10"
                  placeholder={language === "En" ? "Message" : "Poruka"}
                  name="message"
                  onChange={(event) => setMessage(event.target.value)}
                  value={message}
                />
                <button className="custom__button">
                  {language === "En" ? "Send it" : "Pošaljite"}
                </button>
                {done && isFormValid && (
                  <div>
                    {language === "En"
                      ? "Message has been sent"
                      : "Poruka je poslata"}
                  </div>
                )}
                {!isFormValid && (
                  <div>
                    {language === "En"
                      ? "All fields are required"
                      : "Sva polja su obavezna"}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
